import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import styles from "./image.module.scss";
import { getWrapIndex } from "lib/arrayUtils";
import classnames from "classnames/bind";
import { getRandInt } from "lib/numberUtils";
import { BaseImage } from "./BaseImage";
import { isNil, isString } from "lodash";
import { usePrevious } from "react-use";

const classNameBuilder = classnames.bind(styles);

const NUM_BACKGROUND_IMAGES = 6;

export * from "./BaseImage";

const getStaticStartIndex = (src) => {
  if (!isString(src)) {
    return 0;
  }

  try {
    return src.charCodeAt(Math.max(0, src.length - 5)) % NUM_BACKGROUND_IMAGES;
  } catch (err) {
    return 0;
  }
};

export const Image = memo(
  ({
    className,
    imageContainerClassName,
    enableAnimations,
    shouldRenderRandomFirstBg,
    src,
    round,
    rounded,
    size,
    width,
    height,
    hideImage,
    children,
    aspectRatio,
    containerStyle,
    backgroundDuration = 6000,
    ...rest
  }) => {
    const [bgIndex, setBgIndex] = useState(
      shouldRenderRandomFirstBg
        ? getRandInt(NUM_BACKGROUND_IMAGES - 1)
        : getStaticStartIndex(src)
    );
    const prevSrc = usePrevious(src);

    useLayoutEffect(() => {
      if (prevSrc !== src) {
        setBgIndex(
          shouldRenderRandomFirstBg
            ? getRandInt(NUM_BACKGROUND_IMAGES - 1)
            : getStaticStartIndex(src)
        );
      }
    }, [prevSrc, shouldRenderRandomFirstBg, src]);

    useEffect(() => {
      if (!enableAnimations) {
        return;
      }

      setBgIndex((prev) => getWrapIndex(prev, 1, NUM_BACKGROUND_IMAGES));

      const id = setInterval(
        () =>
          setBgIndex((prev) => getWrapIndex(prev, 1, NUM_BACKGROUND_IMAGES)),
        backgroundDuration
      );

      return () => clearInterval(id);
    }, [src, enableAnimations, backgroundDuration]);

    return (
      <div
        style={{
          width: width || (isNil(size) ? undefined : size),
          height: height || (isNil(size) ? undefined : size),
          aspectRatio,
          ...containerStyle,
        }}
        className={classNameBuilder("container", className, {
          round,
          rounded,
        })}
      >
        {!hideImage && (
          <BaseImage
            key={src}
            className={classNameBuilder(
              "image",
              imageContainerClassName,
              `bg-${bgIndex}`
            )}
            errorIconClassName={styles["error-icon"]}
            src={src}
            round={round}
            rounded={rounded}
            size={size}
            width={width}
            height={height}
            style={{
              transitionDuration: backgroundDuration - 200 + "ms",
              color: "red",
            }}
            {...rest}
          />
        )}
        {children}
      </div>
    );
  }
);
