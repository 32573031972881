import { Header } from "components/Common/Header";
import { NavigatorProvider, useNavigator } from "../../NavigatorContext";
import { TooltipProvider } from "contexts/TooltipContext";
import styles from "./landingPageRoot.module.scss";
import classnames from "classnames/bind";
import { useTheme } from "contexts/ThemeContext";
import { Button } from "components/Common/Button";
import { useAppHeader } from "contexts/AppHeaderContext";
import { Helmet } from "react-helmet";
import { TooltipStyleProvider } from "contexts/TooltipStyleContext";
import { Fragment, useRef } from "react";
import { Outlet } from "components/Router/OutletContext";
import { Link } from "components/Common/Link";
import { useLocation } from "@tanstack/react-router";
import { VTTS_CONFIG } from "./LandingPageContent";

const classNameBuilder = classnames.bind(styles);

const NavigationButtons = () => {
  const { navigate } = useNavigator();
  const { isPortalReady, portalRef, setIsPortalReady } = useAppHeader();
  const location = useLocation();
  const pathBase = location.pathname.split("/")?.[1];

  const setRef = (ref) => {
    if (!isPortalReady) {
      setIsPortalReady(true);
    }
    portalRef.current = ref;
  };

  if (pathBase !== "") {
    return (
      <div className={styles["block"]}>
        <div ref={setRef} />
        <a
          href="https://wildsea.charsmith.com"
          className={classNameBuilder("out-link", "compendium-extra-button")}
        >
          <Button>Wildsea VTT</Button>
        </a>
      </div>
    );
  }
  return (
    <div className={styles["nav-buttons"]}>
      <div ref={setRef} />
      <Button onClick={() => navigate({ to: "/" })} active={pathBase === ""}>
        Home
      </Button>
      <div className={styles["divider"]} />
      {Object.entries(VTTS_CONFIG).map(([key, config]) => {
        const { label, compendiumRoute, href, headerClassName } = config;
        return (
          <Fragment key={key}>
            <div className={styles["block"]}>
              <label className={classNameBuilder("button", headerClassName)}>
                {label}
              </label>
              <div className={styles["buttons"]}>
                {compendiumRoute && (
                  <Link to={`/${compendiumRoute}`}>
                    <Button>Compendium</Button>
                  </Link>
                )}
                <a href={href} className={styles["out-link"]}>
                  <Button>VTT</Button>
                </a>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export const LandingPageRoot = () => {
  const { theme } = useTheme();
  const contentRef = useRef();
  return (
    <NavigatorProvider>
      <TooltipProvider>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={`/wildsea-character-sheet/favicon-${theme.secondary}.ico`}
          />
          <title>Charsmith</title>
        </Helmet>
        <div
          className={classNameBuilder(
            "app",
            "primary-theme-dark",
            "secondary-theme-green"
          )}
        >
          <Header
            titleClassName={styles["header"]}
            title={<Link to="/">Charsmith</Link>}
          >
            <NavigationButtons />
          </Header>
          <TooltipStyleProvider scrollRef={contentRef}>
            <div
              className={classNameBuilder("scrollable")}
              ref={contentRef}
              id="main-scroll"
            >
              <div className={classNameBuilder("content")}>
                <Outlet />
              </div>
            </div>
          </TooltipStyleProvider>
        </div>
      </TooltipProvider>
    </NavigatorProvider>
  );
};
