import { createContext, useContext } from "react";
import { Outlet as ExternalOutlet } from "@tanstack/react-router";

const OutletContext = createContext({});

export const Outlet = ({ context }) => {
  return (
    <OutletContext.Provider value={context}>
      <ExternalOutlet />
    </OutletContext.Provider>
  );
};

export const useOutletContext = () => useContext(OutletContext);
