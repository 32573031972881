import { createRouter, createRoute } from "@tanstack/react-router";
import { rootRoute } from "./routes/rootRoute";
import { compendiumRoute } from "components/Compendium/consts/routes";
import { LandingPageContent } from "./routes/LandingPage/LandingPageContent";

const rootRouteChildren = [
  compendiumRoute,
  createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: () => <LandingPageContent />,
  }),
];

const routeTree = rootRoute.addChildren(rootRouteChildren);

export const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => (
    <div>
      <h1 style={{ padding: "30px 40px" }}>Page not found!</h1>
    </div>
  ),
});
