import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from "react";
import {
  DEFAULT_DICE_KEY,
  THEMES,
  primaryThemesSet,
  secondaryThemesSet,
} from "../constants/themes";
import { useLocalStorageState } from "../hooks/useLocalStorage";
import { fpFlow, fpSet } from "lib/fpLodash";

const ThemeContext = createContext(null);

const getSafePrimaryTheme = (theme) => {
  if (primaryThemesSet.has(theme)) {
    return theme;
  }

  return THEMES.PRIMARY.DARK;
};
const getSafeSecondaryTheme = (theme) => {
  if (secondaryThemesSet.has(theme)) {
    return theme;
  }

  return THEMES.SECONDARY.GREEN;
};

export const ThemeProvider = ({ children }) => {
  const [lcPrimaryTheme, setLcPrimaryTheme] =
    useLocalStorageState("lp-primary-theme");
  const [lcSecondaryTheme, setLcSecondaryTheme] =
    useLocalStorageState("lp-secondary-theme");
  const [lcDiceTheme, setLcDiceTheme] = useLocalStorageState("lp-dice-theme");
  const [lcCustomDiceColor, setLcCustomDiceColor] =
    useLocalStorageState("lp-dice-color");
  const [theme, setTheme] = useState({
    primary: getSafePrimaryTheme(lcPrimaryTheme),
    secondary: getSafeSecondaryTheme(lcSecondaryTheme),
    dice: lcDiceTheme || DEFAULT_DICE_KEY,
    customDiceColor: lcCustomDiceColor || "#FF0000",
  });

  const getSetter = useCallback(
    (key, lcUpdater) => (newValue) => {
      fpFlow(lcUpdater, (val) => setTheme(fpSet(key, val)))(newValue);
    },
    []
  );

  const setPrimaryTheme = useMemo(
    () => getSetter("primary", setLcPrimaryTheme),
    [getSetter, setLcPrimaryTheme]
  );
  const setSecondaryTheme = useMemo(
    () => getSetter("secondary", setLcSecondaryTheme),
    [getSetter, setLcSecondaryTheme]
  );
  const setDiceTheme = useMemo(
    () => getSetter("dice", setLcDiceTheme),
    [getSetter, setLcDiceTheme]
  );
  const setCustomDiceColor = useMemo(
    () => getSetter("customDiceColor", setLcCustomDiceColor),
    [getSetter, setLcCustomDiceColor]
  );

  const themeClassNames = useMemo(
    () => [
      generatePrimaryThemeClassName(theme.primary),
      generateSecondaryThemeClassName(theme.secondary),
    ],
    [theme]
  );

  const value = useMemo(
    () => ({
      theme,
      themeClassNames,
      setPrimaryTheme,
      setSecondaryTheme,
      setDiceTheme,
      setCustomDiceColor,
    }),
    [
      theme,
      themeClassNames,
      setPrimaryTheme,
      setSecondaryTheme,
      setDiceTheme,
      setCustomDiceColor,
    ]
  );
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const generatePrimaryThemeClassName = (theme) =>
  `primary-theme-${theme}`;

export const generateSecondaryThemeClassName = (theme) =>
  `secondary-theme-${theme}`;
