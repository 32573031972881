import styles from "./footer.module.scss";
import { Modal } from "components/Common/Modal";
import { usePortal } from "contexts/PortalContext";
import { BaseImage } from "components/Common/Image/Image";
import FireflyLogo from "./firefly-logo.png";
import { usePageConfig } from "hooks/usePageConfig";
import { useIFrame } from "contexts/IframeContext";
import { EmailLink, GeneralLink, PatreonLink } from "./FooterLink";

export const Footer = ({ license = 'By Firefly’s License', title, longTitle, mascot, purchaseLink }) => {
  const aboutUsPortal = usePortal();
  const contactPortal = usePortal();
  const [shouldHideFooter] = usePageConfig(["shouldHideFooter"]);
  const { isIFrame } = useIFrame();

  if (shouldHideFooter || isIFrame) return null;

  return (
    <div className={styles["container"]}>
      <div className={styles["inner-container"]}>
        <BaseImage src={mascot} className={styles["mascot"]} position={"center"} contain/>
        <Modal
          title={"About Us"}
          contentClassName={styles["modal"]}
          portalProps={aboutUsPortal}
        >
          <div className={styles["links"]}>
            <div>
              Hello! We're two web developers who like playing TTRPGs and
              building useful apps. We stumbled on the Wildsea system and fell
              in love with it, so we decided to make a character sheet to help
              us keep track of our games. But things quickly got out of hand as
              we kept coming up with more and more cool features and ideas...
            </div>
            <div>
              We hope these character sheets can make your sessions
              more fun and fluid. If you've enjoyed using it, you can help keep
              it running through our Patreon. 100% of the funds will be used to
              fund the servers; we intend to keep all apps entirely free and
              nonprofit.
            </div>
            <PatreonLink />
            <div>
              We would greatly appreciate if you send any issues, bugs,
              feedback, or even anything you love about the app to our support
              email and we'll try our best to get back to you ASAP!
            </div>
            <EmailLink />
          </div>
        </Modal>
        <Modal
          title={"Contact"}
          contentClassName={styles["modal"]}
          portalProps={contactPortal}
        >
          <div className={styles["links"]}>
            <EmailLink />
            <PatreonLink />
          </div>
        </Modal>
        <div className={styles["legal"]}>
          <div className={styles["main"]}>
           {title} is the copyright of Quillhound Studios and Felix Isaacs
          </div>
          <i className={styles["sub"]}>
            <u>{title} Digital Character Sheet</u> is an independent
            production by the members of charsmith and is not affiliated with
            Mythworks or Felix Isaacs. It is published under the {license}.
          </i>
        </div>
        <div className={styles["links"]}>
          <div className={styles["link-group"]}>
            <div className={styles["main"]}>
              If you enjoy this app, please help keep our servers running:
            </div>
            <PatreonLink />
          </div>
          <div className={styles["link-group"]}>
            <div className={styles["main"]}>
              Support Mythworks by purchasing {longTitle}:
            </div>
            <GeneralLink href={purchaseLink}>
              Browse the {title} collection
            </GeneralLink>
          </div>
          <div className={styles["link-group"]}>
            <div
              className={styles["link"]}
              onClick={() => aboutUsPortal.setIsPortalComponentRendered(true)}
            >
              About Us
            </div>
            <div
              className={styles["link"]}
              onClick={() => contactPortal.setIsPortalComponentRendered(true)}
            >
              Contact
            </div>
          </div>
        </div>
        <div className={styles["small-logos"]}>
          <BaseImage src={mascot} width={100} position={"top"} />
          <BaseImage src={FireflyLogo} width={100} contain />
        </div>
        <BaseImage
          src={FireflyLogo}
          className={styles["firefly-logo-big"]}
          width={150}
          height={150}
          position={"center"}
          contain
        />
      </div>
    </div>
  );
};
