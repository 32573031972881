import React, { useState } from "react";
import styles from "./landingPageContent.module.scss";
import { BaseImage, Image } from "components/Common/Image/Image";
import { Button } from "components/Common/Button";
import classnames from "classnames/bind";
import { entries } from "lodash";
import { usePreloadImages } from "hooks/usePreloadImage";
import bg6 from "components/Common/Image/bg9.png";
import splash1 from "./pico-splash.png";
import { COMPENDIUM_PATHS } from "components/Compendium/consts/routeConsts";
import { STICKERS } from "components/OverlayButtons/ChatBox/images/stickers/stickers";
import { Footer } from "components/Footer/Footer";

const classNameBuilder = classnames.bind(styles);

const VTTS = {
  WILDSEA: "WILDSEA",
  ROOT: "ROOT",
  PICO: "PICO",
};

export const VTTS_CONFIG = {
  [VTTS.WILDSEA]: {
    label: "The Wildsea",
    poster: "/mascot.png",
    buttonClassName: styles["wildsea"],
    buttonIconSrc: "/mascot.png",
    headerClassName: styles["wildsea-header"],
    href: "https://wildsea.charsmith.com",
    backgroundImage: bg6,
    compendiumRoute: COMPENDIUM_PATHS.BASE,
    title: "Wildsea",
    longTitle: "The Wildsea",
    license: "By Firefly's License",
    purchaseLink: "https://www.myth.works/collections/the-wildsea-homepage?srsltid=AfmBOooQvOrAA0H7Ix7o6QjZCoPz9YEJXVriODAaFcgtJOKdqL6aJ_Lc",
  },
  // [VTTS.PICO]: {
  //   label: "PICO (Beta)",
  //   buttonIconSrc: "/pico-mascot.png",
  //   poster: "/pico-mascot.png",
  //   backgroundClassName: styles["pico-bg"],
  //   buttonClassName: styles["pico"],
  //   headerClassName: styles["pico-header"],
  //   href: "https://pico-charsmith-beta.netlify.app/",
  //   backgroundImage: splash1,
  //   title: "PICO",
  //   longTitle: "PICO",
  //   license: "By Firefly's License",
  //   purchaseLink: "https://felixisaacs.itch.io/pico-hogwild-playtest-pre-gens",
  // },
  // [VTTS.ROOT]: {
  //   label: "ROOT",
  //   poster: "/vagrant.png",
  //   backgroundClassName: styles["root-bg"],
  //   buttonIconSrc: "/vagrant.png",
  //   buttonClassName: styles["root"],
  //   backgroundImage: rbg,
  //   href: "https://root.charsmith.com",
  //   title: "ROOT",
  //   longTitle: "ROOT: The RPG",
  //   license: "TBD",
  //   purchaseLink: "https://magpiegames.com/collections/root",
  // },
};

export const LandingPageContent = () => {
  const [hovered, setHovered] = useState(VTTS.WILDSEA);
  usePreloadImages([bg6, splash1]);
  const {
    backgroundImage: bg,
    poster,
    title,
    longTitle,
    license,
    purchaseLink,
  } = VTTS_CONFIG[hovered] || {};

  return (
    <div className={styles["container"]}>
      <Image src={bg} className={styles["background"]} alt="mascot" />
      <div className={styles["content"]}>
        <BaseImage
          key={hovered}
          src={VTTS_CONFIG[hovered]?.poster || "/mascot.png"}
          className={classNameBuilder(
            "bg-container",
            VTTS_CONFIG[hovered]?.backgroundClassName
          )}
          imageClassName={styles["bg"]}
          contain
        />
        <div className={styles["scroll-container"]}>
          <div className={styles["scroll"]}>
            <h2 className={styles["title"]}>Welcome to Charsmith</h2>
            <div className={styles["small-logo"]}>
              <BaseImage src={STICKERS.normalSmile} />
            </div>
            <h3 className={styles["sub-title"]}>Who are we?</h3>
            <p>
              A small dev team driven to build clean and functional VTTs for our
              favorite RPGs
            </p>
            <h3 className={styles["sub-title"]}>
              Try out our Virtual Table Tops
            </h3>
            <div className={styles["buttons"]}>
              {entries(VTTS_CONFIG).map(([key, c]) => {
                const { buttonIconSrc, href, buttonClassName, label } = c;

                return (
                  <a
                    href={href}
                    onMouseEnter={() => setHovered(key)}
                    onTouchStart={() => setHovered(key)}
                    key={key}
                    className={styles["vtt-button-link"]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      className={classNameBuilder(
                        "vtt-button",
                        buttonClassName
                      )}
                    >
                      <BaseImage
                        src={buttonIconSrc}
                        alt="button-logo"
                        contain
                        className={styles["vtt-logo"]}
                      />
                      <div className={styles["label"]}>{label}</div>
                    </Button>
                  </a>
                );
              })}

              <Button className={classNameBuilder("vtt-button", 'placeholder')}>
                <div className={styles["label"]}>More to Come</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer
        mascot={poster}
        title={title}
        longTitle={longTitle}
        license={license}
        purchaseLink={purchaseLink}
      />
    </div>
  );
};
